const BirthdayEvents2024 = [
    {
        id: 1,
        birthdayImage: require("../../../Assets/Events/2024/Birthdays-2024/BDAY-Ankita.webp"),
        birthdayName: "Ankita Dikshit",
        birthdayDate: <span>23<sup>rd</sup> Feb</span>
    },
    {
        id: 2,
        birthdayImage: require("../../../Assets/Events/2024/Birthdays-2024/BDAY-Faisal.webp"),
        birthdayName: "Faisal Khan",
        birthdayDate: <span>07<sup>th</sup> May</span>
    },
    {
        id: 3,
        birthdayImage: require("../../../Assets/Events/2024/Birthdays-2024/BDAY-Deepak.webp"),
        birthdayName: "Deepak Baid",
        birthdayDate: <span>14<sup>th</sup> May</span>
    },
    {
        id: 4,
        birthdayImage: require("../../../Assets/Events/2024/Birthdays-2024/BDAY-Gowthami.webp"),
        birthdayName: "Gowthami N",
        birthdayDate: <span>19<sup>th</sup> May</span>
    },
    {
        id: 5,
        birthdayImage: require("../../../Assets/Events/2024/Birthdays-2024/BDAY-Susmita.webp"),
        birthdayName: "Susmita S",
        birthdayDate: <span>28<sup>th</sup> May</span>
    },
    {
        id: 6,
        birthdayImage: require("../../../Assets/Events/2024/Birthdays-2024/BDAY-Punith.webp"),
        birthdayName: "Punith Raj",
        birthdayDate: <span>28<sup>th</sup> May</span>
    },
    {
        id: 7,
        birthdayImage: require("../../../Assets/Events/2024/Birthdays-2024/BDAY-Shailaja.webp"),
        birthdayName: "Shailaja V B",
        birthdayDate: <span>12<sup>th</sup> August</span>
    },
    {
        id: 8,
        birthdayImage: require("../../../Assets/Events/2022/Birthdays/BDAY_Renu.png"),
        birthdayName: "Renu S",
        birthdayDate: <span>12<sup>th</sup> Sep</span>
    },
];

export default BirthdayEvents2024;