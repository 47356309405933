
const AnniversaryDayData24 = [
    {
        id: 1,
        imageSource: require("../../../Assets/Events/2024/CSIR/Sandhya-Suraksha-Aashram-2024/Sandhya-Suraksha-2024_2.webp")
    },
    {
        id: 2,
        imageSource: require("../../../Assets/Events/2024/CSIR/Sandhya-Suraksha-Aashram-2024/Sandhya-Suraksha-2024_1.webp")
    },
    {
        id: 3,
        imageSource: require("../../../Assets/Events/2024/Anniversary_Day_2024/Anniversary_Day_2024_1.webp")
    },
    {
        id: 4,
        imageSource: require("../../../Assets/Events/2024/CSIR/Sandhya-Suraksha-Aashram-2024/Sandhya-Suraksha-2024_3.webp")
    },
    {
        id: 5,
        imageSource: require("../../../Assets/Events/2024/CSIR/Sandhya-Suraksha-Aashram-2024/Sandhya-Suraksha-2024_4.webp")
    }
];

export default AnniversaryDayData24;