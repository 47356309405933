import React from "react";
import { FaArrowLeft } from "react-icons/fa";
import { NavLink } from "react-router-dom";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import { Autoplay, EffectCoverflow, Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import AnniversaryDayData24 from "../../../../../../Data/Events/2024/AnniversaryDayData24";
import "../../CSSBlogs/MainBlogsCSSFile.css";

const AnniversaryDay2024 = () => {
    return (
        <>
            <section className="carousel_slider_section">
                <div className="container carouselSliderFirstInnerDiv">
                    <div className="row justify-content-center">
                        <div className="col-md-12">
                        <Swiper
                                effect={"coverflow"}
                                grabCursor={true}
                                centeredSlides={true}
                                slidesPerView={"auto"}
                                coverflowEffect={{
                                    rotate: 0,
                                    stretch: 0,
                                    depth: 100,
                                    modifier: 2,
                                    // slideShadows: true,
                                }}
                                // pagination={{
                                //     clickable: true,
                                //     dynamicBullets: true,
                                // }}
                                loop={true}
                                autoplay={{ delay: 3000 }}
                                navigation={true}
                                modules={[EffectCoverflow, Navigation, Autoplay]}
                                className="mySwiper"
                            >
                                {
                                    AnniversaryDayData24.map((items) => {
                                        return (
                                            <SwiperSlide className="swiperSlide" key={items.id}>
                                                <div className="swiper-slide-img">
                                                    <img src={items.imageSource} alt="Avatar" className='nexus' />
                                                </div>
                                            </SwiperSlide>
                                        )
                                    })
                                }
                            </Swiper>
                        </div>
                    </div>
                </div>
            </section>
            <section className="blogs-heading-and-para-section-start-here">
                <div className="container-fluid">
                    <div className="row justify-content-center">
                        <div className="col-md-12 blog-heading-and-para-div">
                            <h1>Celebrating 11<sup>th</sup> Anniversary of Holistic Learning</h1>
                            <p>As we celebrate the eleventh anniversary of our organization, we, the Holistic Learning Tribe, value the academic principles that advance our social and cultural evolution and advance the production of utilities for humanity.</p>
                            <p>Academics and education have been a vital part of our social development where research and innovation hold mindful creation of living pursuit. The life of any civilization has been based on the roots of academic research and growth. Here, at Holistic Learning we aim to recognize the pursuit of knowledge and informative awareness with a focus on cultural modifications, scientific and idealistic advancements, and economic shifts that build mindful recreations and entertainment.</p>
                            <p>August 9th of 2024 hence marked our 11th annual step in the field of academic and intellectual acquaintances where we shared ideas and gained inspiration from each other. In these 11 years of growth, we involved ourselves in reaching significant milestones in our individual lives sustaining the heights of academic excellence, personal growth, and collective nourishment. Apart from mere celebration among the tribe, we at Holistic Learning went a step ahead to share our joy with blessings from several mothers who are continuing their lives in a closed atmosphere of old-age homes. Here, at Sandhya Surkasha (A home for helpless elderly women), we shared breakfast and tried to fill up the morning with some colors and rejoice together with art-craft. On this day, we the holistic learning expressed our gratitude to all our writers, research associates, scientists, theorists, and students for holding the service opportunity.</p>
                        </div>
                        <div className="col-md-6 blog-writer-div">
                            <p>Written By ~ Priyankesh M</p>
                        </div>
                        <div className="col-md-6 blog-button-div">
                            <NavLink to={"/events/more-events"} className="href">
                                <button>
                                    <span className="icon">
                                        <FaArrowLeft />
                                    </span>{" "}
                                    View All Events
                                </button>
                            </NavLink>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default AnniversaryDay2024;
